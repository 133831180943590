import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Footer`}</h1>
    <p>{`The Footer component provides a consistent
place to display secondary content, navigation,
and site information.`}</p>
    <ComponentPreview componentName="footer--default-story" maxWidth="100%" hasReact hasAngular hasHTML titleAttr="Footer Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Nearly all applications should have a
Footer at the bottom of every page. An
example of an exception would be a
marketing landing page with a unique design.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`The Global Links section is for
linking to our Sister Companies.`}</li>
      <li parentName="ul">{`The Local Links section is for
links to pages or resources within
the same application.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Footer must have Global links`}</li>
      <li parentName="ul">{`Footer may have Local links`}</li>
      <li parentName="ul">{`Footer may have Social Media Links`}</li>
      <li parentName="ul">{`Footer may have Awards, badges
(certifications or affiliations) and disclaimers`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      